import React, { Component } from 'react'

export default class head extends Component {
  render() {
    return (
      <div className='Top'>
        <h1>Erick Domínguez</h1>
        <h2>portfolio coming soon</h2>
        </div>
    )
  }
}
